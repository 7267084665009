import React, {useState, useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'
import "../styles/Navbar.css";
import ReorderIcon from '@material-ui/icons/Reorder';

function NavBar() {
    const [expandNavbar, setExpandNavbar] = useState(false);
    const location = useLocation();
    useEffect(()=>{
        setExpandNavbar(false)
    }, [location])
  return (
    <div className='navbar' id={expandNavbar ? 'open' : 'close'}>
        <div className='toggleButton'>
            <button onClick={()=>{
                setExpandNavbar((prev) => !prev);
            }}> 
                <ReorderIcon />
            </button>
        </div>
        <div className='links'>
            <Link to='/'> Home </Link>
            <Link to='/bildung'> Bildung </Link>
            <Link to='/experience'> Erfahrung </Link>
            <Link to='/projects'> Projekt </Link>
            <Link to='/contact'> Kontakt </Link>
        </div>
    </div>
  );
}

export default NavBar